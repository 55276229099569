import { Typography } from '@material-ui/core';
import {
  Column,
  Columns,
  DrawerActions,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { LOAD_PAYMENT_TYPES } from 'shared/helpers/CarrierPaymentTypes';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { formatLoadPayment } from 'shared/helpers/PaymentHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { DottedLine } from 'shared/ui/DottedLine';
import { LoadSendInvoiceDTO } from '../data/LoadActionsDTO';
import { OrdersDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { LoadSendInvoiceRequestPayment } from './LoadSendInvoiceRequestPayment';

type PaymentMethodProp = typeof LOAD_PAYMENT_TYPES | string | undefined | null;

export interface LoadSendInvoiceDrawerActionsProps {
  submitDisabled?: boolean;
  orders?: Array<Partial<OrdersDTO>>;
  paymentMethod: PaymentMethodProp;
  isCreatedByBroker?: boolean;
}

export function LoadSendInvoiceDrawerActions({
  submitDisabled = false,
  orders,
  paymentMethod,
  isCreatedByBroker,
}: LoadSendInvoiceDrawerActionsProps) {
  const { isSubmitting } = useFormikContext<LoadSendInvoiceDTO>();
  const { values } = useFormikContext<LoadSendInvoiceDTO>();
  const {
    invoice_date,
    invoice_id,
    factor_this_invoice: hasFactorThisInvoice,
    bol_template,
    is_carrier_requested_superpay: isCarrierRequestedSuperPay,
  } = values;
  const totalPrice = useMemo(() => {
    let total = 0;
    const isOrdersValid =
      orders && orders.length > 0 && orders.every((val) => Boolean(val));
    if (isOrdersValid) {
      total = orders.reduce(
        (current, so) => current + Number(so.invoice_price),
        0,
      );
    }
    return total;
  }, [orders]);

  const paymentMethodType = useMemo(() => {
    if (orders && orders.length > 1) {
      return 'Mixed';
    }
    return formatLoadPayment(paymentMethod);
  }, [orders, paymentMethod]);

  const guids: string =
    orders?.map((order) => order.guid as string).join(',') ?? '';

  return (
    <DrawerActions>
      <Box width="100%" paddingBottom="medium" paddingTop="medium">
        <Stack space="small">
          <Column width="fluid">
            <DecriptionRow title="Method" value={paymentMethodType} />
          </Column>
          <LoadSendInvoiceRequestPayment
            paymentMethod={paymentMethod}
            isCreatedByBroker={isCreatedByBroker}
          />
          <Column width="fluid">
            <DecriptionRow
              title="Total"
              boldValue={true}
              value={formatCurrency(totalPrice)}
            />
          </Column>

          <Inline>
            <Button
              pending={isSubmitting}
              disabled={submitDisabled}
              type="submit"
              variant="primary"
            >
              Send Invoice
            </Button>
            <Button
              disabled={!orders?.length}
              onClick={() => {
                if (!orders) {
                  return;
                }
                trackLoadsEvent({ name: 'Carrier Clicked Preview Invoice' });

                openExternalURL(
                  parseURITemplate(
                    '/tms/loads?send_invoice=preview&guids={guids}&invoice_date={invoice_date}&invoice_id={invoice_id}&factor_this_invoice={factor_this_invoice}&bol_template={bol_template}&is_carrier_requested_superpay={is_carrier_requested_superpay}',
                    {
                      send_invoice: 'preview',
                      guids,
                      invoice_date,
                      invoice_id,
                      factor_this_invoice: hasFactorThisInvoice,
                      bol_template,
                      is_carrier_requested_superpay: isCarrierRequestedSuperPay,
                    },
                  ),
                  {
                    target: '_blank',
                  },
                );
              }}
              variant="neutral"
            >
              Preview
            </Button>
          </Inline>
        </Stack>
      </Box>
    </DrawerActions>
  );
}

interface DecriptionRowParams {
  title: string;
  value: string | number | JSX.Element;
  boldValue?: boolean;
  e2eValue?: string;
}

function DecriptionRow({
  title,
  e2eValue,
  value,
  boldValue,
}: DecriptionRowParams) {
  return (
    <Columns align="center">
      <Column width="content">
        <Typography
          variant={boldValue ? 'h5' : 'caption'}
          color={boldValue ? undefined : 'textSecondary'}
          gutterBottom={true}
        >
          {title}
        </Typography>
      </Column>
      <Column width="fluid">
        <DottedLine />
      </Column>
      <Column width="content">
        <Typography
          align="right"
          color="textPrimary"
          variant={boldValue ? 'h5' : 'caption'}
          data-e2e={e2eValue}
        >
          {value}
        </Typography>
      </Column>
    </Columns>
  );
}
