import { Tooltip, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useFlag } from 'shared/settings/FeatureToggles';

interface InviteDispatcherButtonProps {
  handleOpen: () => void;
  isInviteDispatcherLoading: boolean;
}

export function InviteDispatcherButton({
  handleOpen,
  isInviteDispatcherLoading,
}: InviteDispatcherButtonProps) {
  const shouldShowDispatcherInviteButton = useFlag(
    'dispatcher_management_invite_form',
  );

  return (
    <Tooltip
      placement="right"
      title={
        !shouldShowDispatcherInviteButton ? (
          <Stack space="none">
            <Typography>Will be available next week.</Typography>
            <Typography>Stay tuned!</Typography>
          </Stack>
        ) : (
          ''
        )
      }
    >
      <Box>
        <Button
          onClick={handleOpen}
          disabled={!shouldShowDispatcherInviteButton}
          fullWidth={true}
          startIcon={<Add />}
          pending={isInviteDispatcherLoading}
        >
          Invite Dispatcher
        </Button>
      </Box>
    </Tooltip>
  );
}
