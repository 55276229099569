import { Divider } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { FormikNumberField } from '@superdispatch/forms';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { HALF_OF_A_SECOND } from 'shared/constants/NumberConstants';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { DriveawayIcon } from 'shared/icons/DriveawayIcon';
import styled from 'styled-components';
import {
  useEstimateSeatsUpgrade,
  useEstimateTaxPrice,
  useSubscription,
} from '../SubscriptionAPI';

const StyledNumberField = styled(FormikNumberField)`
  .MuiInputBase-root {
    background-color: ${ColorDynamic.White};
    min-width: 144px;
  }

  @media (max-width: 768px) {
    .MuiInputBase-root {
      min-width: 108px;
    }
  }

  .MuiInputBase-input {
    text-align: center;
  }
`;
const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    padding: 4px 0;
    font-size: 16px;
    min-width: 26px;
  }
`;

interface SeatBasedPricingBoxProps {
  isMinusDisabled: boolean;
  isReadOnly?: boolean;
}

export function SeatBasedPricingBox({
  isMinusDisabled,
  isReadOnly,
}: SeatBasedPricingBoxProps) {
  const [field, _, { setValue }] = useField<number>('seats_count');
  const {
    isSubscriptionInTrial,
    isSubscriptionOnLitePlan,
    billableSeats,
    isDriverSeat,
    seatType,
  } = useSubscription();

  const isEstimateInvoiceEnabled =
    (isSubscriptionInTrial || isSubscriptionOnLitePlan) &&
    !!billableSeats?.billable_seats;

  const debouncedSeatsCount = useDebouncedValue(field.value, HALF_OF_A_SECOND);

  const { data: taxPrice, isFetching: isTaxPriceLoading } = useEstimateTaxPrice(
    {
      seats_count: debouncedSeatsCount || 1,
      include_total_invoice: false,
    },
    {
      enabled: isEstimateInvoiceEnabled,
      keepPreviousData: true,
      cacheTime: 1000,
    },
  );

  const {
    data: estimateSeatsUpgrade,
    isFetching: isEstimateSeatsUpgradeLoading,
  } = useEstimateSeatsUpgrade(debouncedSeatsCount || 1, {
    enabled: !isSubscriptionOnLitePlan && !isSubscriptionInTrial,
    keepPreviousData: true,
    cacheTime: 1000,
  });

  const unitPrice =
    taxPrice?.plan?.unit_price || estimateSeatsUpgrade?.plan?.unit_price;
  const totalPrice =
    taxPrice?.plan?.amount || estimateSeatsUpgrade?.plan?.amount;

  const isMinusButtonDisabled = field.value <= 1 || isMinusDisabled;

  return (
    <Box
      paddingTop="small"
      paddingBottom="small"
      paddingLeft="medium"
      paddingRight="medium"
      backgroundColor="Silver200"
      borderRadius="small"
    >
      <Stack space="small">
        <Columns space="large" align="center">
          <Column width="content">
            <Box maxWidth="140px">
              <Stack space="xxsmall">
                <TextBox variant="heading-6">Pro</TextBox>
                <TextBox variant="caption" color="secondary">
                  {formatCurrency(unitPrice)} / {seatType} / month
                </TextBox>
              </Stack>
            </Box>
          </Column>
          <Column width="fluid">
            <Columns space="xsmall" align="center" collapseBelow="desktop">
              <Column width="4/5">
                <StyledNumberField
                  name="seats_count"
                  InputProps={{
                    readOnly: isReadOnly,
                    startAdornment: (
                      <StyledButton
                        onClick={() => {
                          void setValue(field.value - 1);
                        }}
                        variant="neutral"
                        size="small"
                        disabled={isMinusButtonDisabled}
                      >
                        <Remove
                          fontSize="inherit"
                          color={isMinusButtonDisabled ? 'inherit' : 'action'}
                        />
                      </StyledButton>
                    ),
                    endAdornment: (
                      <StyledButton
                        onClick={() => {
                          void setValue(field.value + 1);
                        }}
                        variant="neutral"
                        size="small"
                      >
                        <Add
                          fontSize="inherit"
                          htmlColor={ColorDynamic.Dark100}
                        />
                      </StyledButton>
                    ),
                  }}
                  inputProps={{
                    decimalScale: 0,
                    allowNegative: false,
                    allowLeadingZeros: false,
                  }}
                />
              </Column>

              <Column width="content">
                <TextBox variant="body-semibold" color="secondary">
                  {isDriverSeat
                    ? field.value > 1
                      ? 'drivers'
                      : 'driver'
                    : field.value > 1
                    ? 'seats'
                    : 'seat'}
                </TextBox>
              </Column>
            </Columns>
          </Column>
        </Columns>

        <TextBox align="center" variant="caption" color="secondary">
          Each seat can be used for a driver{' '}
          {!isDriverSeat ? 'or dispatcher' : ''}
        </TextBox>

        <Divider />

        <Inline space="small" verticalAlign="center" horizontalAlign="center">
          <Inline verticalAlign="center" space="xxsmall">
            <DriveawayIcon htmlColor={ColorDynamic.Dark100} />
            <TextBox variant="body-semibold" color="secondary">
              {field.value} {isDriverSeat ? 'driver' : ''}{' '}
              {field.value > 1 ? 'seats' : 'seat'}
            </TextBox>
          </Inline>
          {isTaxPriceLoading || isEstimateSeatsUpgradeLoading ? (
            <Skeleton variant="text" width={100} height={24} />
          ) : (
            <Inline space="none" verticalAlign="center">
              <TextBox variant="heading-4">
                {formatCurrency(totalPrice)}
              </TextBox>
              <TextBox variant="body-semibold" color="secondary">
                /month
              </TextBox>
            </Inline>
          )}
        </Inline>
      </Stack>
    </Box>
  );
}
