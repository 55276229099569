import { AppBar, CircularProgress, Dialog, Toolbar } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { usePrintHTMLContent } from 'shared/hooks/usePrintHTMLContent';
import styled from 'styled-components';
import { useGetCombinedInvoicePDF } from '../data/LoadsAPI';
import { useLoadsPageContext } from '../loads-page/data/LoadsPageParamsContext';

interface LoadInvoicePreviewDialogProps {
  guids: string;
  open: boolean;
  onClose: () => void;
}

export function LoadInvoicePreviewDialog({
  open,
  guids,
  onClose,
}: LoadInvoicePreviewDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={true}>
      <LoadInvoicePreviewDialogContent guids={guids} onClose={onClose} />
    </Dialog>
  );
}

interface LoadInvoicePreviewDialogContentProps {
  guids: string;
  onClose: () => void;
}

export const PreviewBox = styled.div`
  .page {
    background: ${Color.White};
    color: ${Color.Dark500};
  }
`;

function LoadInvoicePreviewDialogContent({
  guids,
}: LoadInvoicePreviewDialogContentProps) {
  const { params } = useLoadsPageContext();

  const invoiceDateFormatted = DateTime.fromISO(
    params.invoice_date || '',
  ).toFormat("yyyy-MM-dd'T'HH:mm:ss");

  const { data, isLoading, isFetching } = useGetCombinedInvoicePDF({
    load_guids: guids,
    invoice_number: params.invoice_id ?? '',
    invoice_date: invoiceDateFormatted,
    bol_template: params.bol_template ?? 'default',
    factor_this_invoice: !!params.factor_this_invoice,
    is_carrier_requested_superpay:
      params.is_carrier_requested_superpay ?? undefined,
  });
  usePrintHTMLContent(data?.html || '');

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <TextBox variant="heading-3">Invoice Preview</TextBox>
        </Toolbar>
      </AppBar>

      <Box
        width="100%"
        height="100%"
        marginLeft="auto"
        marginRight="auto"
        marginTop="xxlarge"
        marginBottom="small"
        paddingTop="xxlarge"
        overflowX="hidden"
      >
        {isLoading || isFetching || !data?.html ? (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          // eslint-disable-next-line @typescript-eslint/naming-convention
          <PreviewBox dangerouslySetInnerHTML={{ __html: data.html }} />
        )}
      </Box>
    </>
  );
}
