import { has } from 'lodash-es';
import { MouseEvent } from 'react';
import { logError } from 'shared/helpers/ErrorTracker';
import { tryParseJSON } from 'shared/utils/DataUtils';

function debug(format: string, ...args: unknown[]): void {
  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.debug(`MobileAppBridge: ${format}`, ...args);
  }
}

function warning(error: unknown) {
  if (import.meta.env.MODE !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

type MobileFeatureToggle = 'isWebViewDynamicNavigationEnabled';
type MobileFeatureToggles = Record<MobileFeatureToggle, boolean>;

export interface BaseMobileAppInterface {
  getToken?: () => string | undefined;
  getDeviceGuid?: () => string | undefined;
  getDriverGuid?: () => string | undefined;
  getCarrierGuid?: () => string | undefined;
  isDarkMode?: () => boolean;
  getFeatureToggles?: () => MobileFeatureToggles | string; // String is in JSON format
}

export interface MobileAppInterface extends BaseMobileAppInterface {
  openProfile?: () => void;
  openSettings?: () => void;
  openDriverInformation?: () => void;
  openDriverPreferences?: () => void;
  openCarrierInformation?: () => void;
  openExternalUrl?: (url: string) => void;
  openACHPaymentInformation?: () => void;
  openAvailableLoads?: () => void;
  openVerifiedCarrierPromotionalModal?: () => void;
  openUnverifiedCarrierRequestWallModal?: () => void;
  sendDriverUpdatedEvent?: () => void;
  sentSuperPayRequestShipper?: () => void;
  closeScreen?: () => void;
  updateSuggestedLoads?: (unseenCount: number) => void;
  acceptedShipperLimitedExperience?: (doNotWarnAgain: boolean) => void;
}

declare global {
  interface Window {
    MobileLoadboardInterface?: MobileAppInterface;
    webkit?: { messageHandlers?: { slbMessageHandler?: MessageHandler } };
  }
}

export type MessageHandlerPayload =
  | {
      type:
        | 'open_profile'
        | 'open_settings'
        | 'open_driver_preferences'
        | 'open_driver_information'
        | 'open_carrier_information'
        | 'open_ach_payment_information'
        | 'open_verified_carrier_promotional_modal'
        | 'open_unverified_carrier_request_wall_modal'
        | 'send_driver_updated_event'
        | 'sent_superpay_request_shipper'
        | 'close_screen'
        | 'open_available_loads';
    }
  | { type: 'open_external_url'; data: { url: string } }
  | { type: 'update_suggested_loads'; data: { unseen_count: number } }
  | {
      type: 'accepted_shipper_limited_experience';
      data: { do_not_warn_again: boolean };
    };

interface MessageHandler {
  postMessage: (payload: MessageHandlerPayload) => void;
}

function execute(
  bridgeMethod: (bridge: MobileAppInterface) => void,
  messageHandlerMethod: (messageHandler: MessageHandler) => void,
  fallbackMethod: () => void,
): boolean {
  const messageHandler = window.webkit?.messageHandlers?.slbMessageHandler;

  // Try WebKit handler first and do not return false on error.
  // We want to try call bridge event after all.
  if (messageHandler) {
    try {
      messageHandlerMethod(messageHandler);

      return true;
    } catch (error: unknown) {
      warning(error);
      logError(error, 'MobileAppInterface#messageHandlerMethod');
    }
  }

  const mobileInterface = window.MobileLoadboardInterface;

  if (mobileInterface) {
    try {
      bridgeMethod(mobileInterface);

      return true;
    } catch (error: unknown) {
      warning(error);
      logError(error, 'MobileAppInterface#bridgeMethod');

      return false;
    }
  }

  if (import.meta.env.MODE !== 'production') {
    fallbackMethod();
  }

  return false;
}

export function getMobileAppToken(): string | undefined {
  const bridge = window.MobileLoadboardInterface;

  try {
    return bridge?.getToken?.();
  } catch (error: unknown) {
    logError(error, 'MobileAppInterface#getToken', {
      extraInfo: { bridge },
    });

    return undefined;
  }
}

export class MobileAppBridge {
  static isInjected(): boolean {
    return window.MobileLoadboardInterface != null;
  }

  static getDriverGuid(): string | undefined {
    return window.MobileLoadboardInterface?.getDriverGuid?.();
  }

  static getDeviceGuid(): string | undefined {
    return window.MobileLoadboardInterface?.getDeviceGuid?.();
  }

  static getCarrierGuid(): string | undefined {
    return window.MobileLoadboardInterface?.getCarrierGuid?.();
  }

  static getFeatureToggles(): MobileFeatureToggles | undefined {
    const value = window.MobileLoadboardInterface?.getFeatureToggles?.();

    if (typeof value === 'string') {
      return tryParseJSON<MobileFeatureToggles>(value);
    }

    return value;
  }

  static isDynamicNavigationEnabled(): boolean {
    return !!MobileAppBridge.getFeatureToggles()
      ?.isWebViewDynamicNavigationEnabled;
  }

  static openProfile = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.openProfile?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({ type: 'open_profile' });
      },
      () => {
        debug('openProfile');
      },
    );

  static openSettings = (): boolean =>
    execute(
      (mobileInterface) => {
        if (mobileInterface.openSettings) {
          mobileInterface.openSettings();
        } else {
          MobileAppBridge.openProfile();
        }
      },
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({ type: 'open_settings' });
      },
      () => {
        debug('openSettings');
      },
    );

  static openDriverInformation = (): boolean =>
    execute(
      (mobileInterface) => {
        if (typeof mobileInterface.openDriverInformation == 'function') {
          mobileInterface.openDriverInformation();
        } else {
          MobileAppBridge.openProfile();
        }
      },
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({ type: 'open_driver_information' });
      },
      () => {
        debug('openDriverInformation');
      },
    );

  static openCarrierInformation = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.openCarrierInformation?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({ type: 'open_carrier_information' });
      },
      () => {
        debug('openCarrierInformation');
      },
    );

  static openExternalUrl = (url: string): boolean =>
    execute(
      (mobileInterface) => mobileInterface.openExternalUrl?.(url),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'open_external_url',
          data: { url },
        });
      },
      () => {
        debug('openExternalUrl');
      },
    );

  static openAvailableLoads = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.openAvailableLoads?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'open_available_loads',
        });
      },
      () => {
        debug('openAvailableLoads');
      },
    );

  static openACHPaymentInformation = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.openACHPaymentInformation?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'open_ach_payment_information',
        });
      },
      () => {
        debug('openACHPaymentInformation');
      },
    );

  static openVerifiedCarrierPromotionalModal = (): boolean =>
    execute(
      (mobileInterface) =>
        mobileInterface.openVerifiedCarrierPromotionalModal?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'open_verified_carrier_promotional_modal',
        });
      },
      () => {
        debug('openVerifiedCarrierPromotionalModal');
      },
    );

  static openUnverifiedCarrierRequestWallModal = (): boolean =>
    execute(
      (mobileInterface) =>
        mobileInterface.openUnverifiedCarrierRequestWallModal?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'open_unverified_carrier_request_wall_modal',
        });
      },
      () => {
        debug('openUnverifiedCarrierRequestWallModal');
      },
    );

  static sendDriverUpdatedEvent = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.sendDriverUpdatedEvent?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'send_driver_updated_event',
        });
      },
      () => {
        debug('sendDriverUpdatedEvent');
      },
    );

  static interceptLinkClick = (event: MouseEvent<HTMLAnchorElement>): void => {
    if (MobileAppBridge.openExternalUrl(event.currentTarget.href)) {
      event.preventDefault();
    }
  };

  static updateSuggestedLoads = (data: { unseen_count: number }): boolean =>
    execute(
      (mobileInterface) =>
        mobileInterface.updateSuggestedLoads?.(data.unseen_count),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'update_suggested_loads',
          data,
        });
      },
      () => {
        debug('updateSuggestedLoads');
      },
    );

  static openDriverPreferences = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.openDriverPreferences?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'open_driver_preferences',
        });
      },
      () => {
        debug('openDriverPreferences');
      },
    );

  static sentSuperPayRequestShipper = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.sentSuperPayRequestShipper?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'sent_superpay_request_shipper',
        });
      },
      () => {
        debug('sentSuperPayRequestShipper');
      },
    );

  static acceptShipperLimitedExperience = (doNotWarn: boolean): boolean =>
    execute(
      (mobileInterface) =>
        mobileInterface.acceptedShipperLimitedExperience?.(doNotWarn),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'accepted_shipper_limited_experience',
          data: {
            do_not_warn_again: doNotWarn,
          },
        });
      },
      () => {
        debug('acceptShipperLimitedExperience');
      },
    );

  static closeScreen = (): boolean =>
    execute(
      (mobileInterface) => mobileInterface.closeScreen?.(),
      (webkitMessageHandler) => {
        webkitMessageHandler.postMessage({
          type: 'close_screen',
        });
      },
      () => {
        debug('closeScreen');
      },
    );

  static hasMethodSupported = (method: keyof MobileAppInterface): boolean =>
    has(window.MobileLoadboardInterface, method);

  static isDarkMode = (): boolean =>
    window.MobileLoadboardInterface?.isDarkMode?.() || false;

  static listenDarkModeChanges = (callback: (isDarkMode: boolean) => void) => {
    function handleDarkModeChange(event: CustomEvent<{ isDarkMode: boolean }>) {
      callback(event.detail.isDarkMode);
    }

    window.addEventListener(
      'driver_app_theme_mode_changed',
      handleDarkModeChange,
    );

    return () => {
      window.removeEventListener(
        'driver_app_theme_mode_changed',
        handleDarkModeChange,
      );
    };
  };
}
