import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { FormikMaxLengthTextField } from '@superdispatch/forms';
import {
  ExitTransitionPlaceholder,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useAppFormik } from 'shared/form/AppFormik';
import { useCarrierRatingsAPI } from './data/CarrierRatingsAPI';
import {
  CarrierRatingDTO,
  carrierReviewResponseSchema,
} from './data/CarrierRatingsDTO';

interface Props {
  rating?: CarrierRatingDTO;
  onClose?: () => void;
}

export function CarrierReviewResponseDialog({ rating, onClose }: Props) {
  const isEditing = !!rating?.response;
  const isRatingPending = rating?.status === 'PENDING';
  const isFullScreen = useResponsiveValue(true, false, false);

  const { respondRating } = useCarrierRatingsAPI();
  const formik = useAppFormik({
    key: rating?.guid,
    validationSchema: carrierReviewResponseSchema,
    initialValues: { response: rating?.response || '' },
    onSubmit: (data) => {
      if (rating?.guid) {
        return respondRating(rating.guid, data);
      }

      return Promise.reject();
    },
    onSubmitSuccess: onClose,
    getSuccessMessage: () =>
      isEditing ? 'Response updated' : 'Response added',
    getErrorMessage: ({ message: errorMessage }) =>
      errorMessage || `Cannot ${isEditing ? 'update' : 'add'} response`,
  });

  const { isSubmitting } = formik;
  const isOpen = isSubmitting || !!rating;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullScreen={isFullScreen}
    >
      <ExitTransitionPlaceholder in={isOpen}>
        <DialogTitle>
          <Typography variant="h3" gutterBottom={true}>
            {isEditing ? 'Update Response' : 'Response to Rating'}
          </Typography>

          {isRatingPending && (
            <Typography color="textSecondary">
              Response will be visible only for you and for the Shipper.
            </Typography>
          )}
        </DialogTitle>

        <DialogContent>
          <Box
            width={['auto', '312px']}
            paddingTop={['none', 'xsmall']}
            paddingBottom="medium"
          >
            <FormikProvider value={formik}>
              <FormikMaxLengthTextField
                name="response"
                maxLength={280}
                minRows={4}
                multiline={true}
                fullWidth={true}
              />
            </FormikProvider>
          </Box>

          {isRatingPending && (
            <Box
              padding="xsmall"
              marginBottom="small"
              borderRadius="small"
              backgroundColor="Yellow50"
            >
              Responses will not be publicly visible until the 10 day pending
              period has ended.
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="neutral" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>

          <Button
            autoFocus={true}
            disabled={!rating?.guid || !formik.dirty || !formik.values.response}
            pending={isSubmitting}
            onClick={() => {
              void formik.submitForm();
            }}
          >
            {isEditing ? 'Update' : 'Add Response'}
          </Button>
        </DialogActions>
      </ExitTransitionPlaceholder>
    </Dialog>
  );
}
