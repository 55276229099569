import { ListItem, ListItemText, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  Inline,
  useResponsiveValue,
  VisibilityObserver,
} from '@superdispatch/ui';
import { Box, Sidebar, SidebarMenuItem, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { useSubscription } from 'shared/modules/subscription/SubscriptionAPI';
import { DispatcherAvatar } from '../core/DispatcherAvatar';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { InviteDispatcherButton } from './DispatcherInviteButton';
import { DispatcherInviteDialog } from './DispatcherInviteDialog';
import { DispatchersSidebarHeader } from './DispatchersSidebarHeader';

export function DispatchersListSidebar() {
  const isMobile = useResponsiveValue(true, false);
  const {
    openDispatcher,
    currentDispatcherGUID,
    searchQueryDrivers,
    dispatcherList,
  } = useDispatcherPageContext();
  const { isDriverSeat } = useSubscription();
  const nextPlanEstimate = useNextPlanEstimate();
  const [currentModal, setCurrentModal] = useState<'invite' | 'plan_upgrade'>();
  const [searchText] = searchQueryDrivers;

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    dispatcherList;

  const list = useMemo(() => data?.pages.flatMap((item) => item.data), [data]);

  function handleOpen() {
    trackDispatchersEvent({ name: 'Carrier Clicked Invite Dispatcher' });
    void nextPlanEstimate.refetch().then(({ data: nextPlan }) => {
      if (nextPlan?.plan && !isDriverSeat) {
        setCurrentModal('plan_upgrade');
      } else {
        setCurrentModal('invite');
      }
    });
  }

  return (
    <>
      <DispatcherInviteDialog
        open={currentModal === 'invite'}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onOpen={() => {
          setCurrentModal('invite');
        }}
      />

      <PlanUpdateDrawer
        open={currentModal === 'plan_upgrade' && !nextPlanEstimate.isFetching}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onSubmitSuccess={() => {
          setCurrentModal('invite');
        }}
      />

      <Sidebar
        title={
          <Columns align="center">
            <Column width="1/2">
              <Typography variant="h3">Dispatchers</Typography>
            </Column>
            {isMobile && (
              <Column width="1/2">
                <Inline horizontalAlign="right">
                  <InviteDispatcherButton
                    handleOpen={handleOpen}
                    isInviteDispatcherLoading={nextPlanEstimate.isFetching}
                  />
                </Inline>
              </Column>
            )}
          </Columns>
        }
        header={
          <DispatchersSidebarHeader
            isInviteDispatcherLoading={nextPlanEstimate.isFetching}
            handleOpen={handleOpen}
          />
        }
      >
        {!data?.pages.length ? (
          <ListItem>
            <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          </ListItem>
        ) : !list?.length ? (
          <Box padding="small">
            <TextBox color="secondary" align="center">
              {searchText ? 'No search results' : 'No dispatchers'}
            </TextBox>
          </Box>
        ) : (
          list.map((dispatcher) => {
            const isCurrent = currentDispatcherGUID === dispatcher.guid;
            const isInvitedDispatcher =
              dispatcher.connection_status === 'pending' &&
              dispatcher.connection_type === 'invite';
            const dispatcherLabel = isInvitedDispatcher
              ? dispatcher.email
              : dispatcher.name;

            return (
              <SidebarMenuItem
                key={dispatcher.guid}
                selected={isCurrent}
                onClick={() => {
                  openDispatcher(dispatcher.guid);
                }}
                avatar={<DispatcherAvatar dispatcher={dispatcher} />}
              >
                {dispatcherLabel}
              </SidebarMenuItem>
            );
          })
        )}

        {hasNextPage && (
          <VisibilityObserver
            onChange={(visibility) => {
              if (!isFetchingNextPage && visibility === 'visible') {
                void fetchNextPage();
              }
            }}
            render={({ ref }) => (
              <ListItem ref={ref}>
                <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
              </ListItem>
            )}
          />
        )}
      </Sidebar>
    </>
  );
}
