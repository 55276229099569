import { Link, Typography } from '@material-ui/core';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { EncryptOutlinedIcon } from 'shared/icons/EncryptOutlinedIcon';
import {
  useEstimateTaxPrice,
  useSubscription,
} from 'shared/modules/subscription/SubscriptionAPI';
import styled from 'styled-components';

const PlanInformationDivider = styled.div`
  border-bottom: 1px dotted ${ColorDynamic.Silver400};
  height: 18px;
`;
const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

interface SubscriptionSummaryProps {
  disabled: boolean;
  seatsCount: number;
  onContinue?: () => void;
}

export function SubscriptionSummary({
  seatsCount,
  disabled,
  onContinue,
}: SubscriptionSummaryProps) {
  const { isSubmitting } = useFormikContext();
  const { isDriverSeat, seatType } = useSubscription();
  const { data: taxPrice } = useEstimateTaxPrice(
    {
      seats_count: seatsCount,
      include_total_invoice: false,
    },
    {
      enabled: !!seatsCount,
      keepPreviousData: true,
      cacheTime: 1000,
    },
  );

  return (
    <Stack space="small">
      <Stack space="small">
        <Typography display="inline" variant="h3">
          Order Summary
        </Typography>

        <Stack space="xxsmall">
          <Columns space="xsmall">
            <Column width="content">
              <Typography>Pro</Typography>
            </Column>
            <Column width="fluid">
              <PlanInformationDivider />
            </Column>
            <Column width="content">
              <Typography>{formatCurrency(taxPrice?.plan?.amount)}</Typography>
            </Column>
          </Columns>
          <Typography color="textSecondary">
            {formatCurrency(taxPrice?.plan?.unit_price, {
              maximumFractionDigits: 0,
            })}{' '}
            / {seatType} / month x {seatsCount}{' '}
            {isDriverSeat
              ? formatPlural(seatsCount, 'driver', 'drivers')
              : formatPlural(seatsCount, 'seat', 'seats')}
          </Typography>
          <Typography color="textSecondary">Billed monthly</Typography>
        </Stack>

        <Stack space="xsmall">
          <Columns space="xsmall">
            <Column width="content">
              <Typography>Total</Typography>
            </Column>
            <Column width="fluid">
              <PlanInformationDivider />
            </Column>
            <Column width="content">
              <Typography variant="h4">
                {formatCurrency(taxPrice?.plan?.amount)}
              </Typography>
            </Column>
          </Columns>
          <Typography align="right">+ tax if applicable</Typography>
        </Stack>

        <StyledButton
          startIcon={<EncryptOutlinedIcon />}
          fullWidth={true}
          size="large"
          onClick={onContinue}
          disabled={disabled}
          pending={isSubmitting}
        >
          Continue to Upgrade
        </StyledButton>

        <Inline horizontalAlign="center">
          <Typography align="center" color="textSecondary" variant="caption">
            By continuing, you agree to our{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.superdispatch.com/terms-of-service"
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.superdispatch.com/hc/articles/35648690974739"
            >
              Refund Policy
            </Link>
          </Typography>
        </Inline>
      </Stack>
    </Stack>
  );
}
