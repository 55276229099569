import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency, formatPlural } from 'shared/helpers/IntlHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  useEstimateTaxPrice,
  useSubscription,
} from 'shared/modules/subscription/SubscriptionAPI';

export function PaywallSubscriptionPlan() {
  const {
    seatType,
    isDriverSeat,
    subscription: subscriptionDetails,
    billableSeats,
  } = useSubscription();
  const seatsCount = billableSeats?.billable_seats;
  const { data: taxPrice } = useEstimateTaxPrice(
    {
      seats_count: seatsCount,
      include_total_invoice: false,
    },
    {
      enabled: !!seatsCount,
      keepPreviousData: true,
      cacheTime: 1000,
    },
  );

  if (!subscriptionDetails?.subscription?.plan) return null;

  return (
    <Box backgroundColor="Silver200" borderRadius="small" padding="small">
      <Columns align="center">
        <Column width="1/2">
          <Stack space="xxsmall">
            <TextBox variant="heading-6">Pro</TextBox>
            <TextBox align="left" color="primary" variant="body-semibold">
              {formatCurrency(taxPrice?.plan?.amount)}
            </TextBox>
            <TextBox variant="caption" color="secondary">
              {formatCurrency(taxPrice?.plan?.unit_price, {
                maximumFractionDigits: 0,
              })}{' '}
              / {seatType} / month x {seatsCount}{' '}
              {isDriverSeat
                ? formatPlural(seatsCount, 'driver', 'drivers')
                : formatPlural(seatsCount, 'seat', 'seats')}
            </TextBox>
          </Stack>
        </Column>
        <Column width="1/2">
          <Button
            fullWidth={true}
            variant="text"
            onClick={() => {
              openExternalURL('/tms/drivers', { target: '_blank' });
              trackEvent('Carrier Clicked Manage Drivers', {
                utm_medium: 'Paywall Dialog',
              });
            }}
          >
            Manage Drivers
          </Button>
        </Column>
      </Columns>
    </Box>
  );
}
