import { Menu, MenuItem } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import {
  Inline,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { DriverDTO } from 'shared/modules/driver/DriverDTO';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { MenuButton } from 'shared/ui/MenuButton';
import { useResendInviteDriver } from '../data/DriversAPI';
import { DriverInviteCancelDialog } from '../driver-invitation/DriverInvitationCancelDialog';
import { useDriversPageContext } from '../DriversPageContext';

export function DriversPageHeaderInviteAction() {
  const { addSnackbar } = useSnackbarStack();
  const isMobile = useResponsiveValue(true, false);
  const { currentDriverGUID, driver } = useDriversPageContext();
  const [isCancelInvite, setIsCancelInvite] = useState(false);
  const [currentModal, setCurrentModal] = useState<
    'plan_upgrade' | undefined
  >();
  const nextPlanEstimate = useNextPlanEstimate();

  const { mutate: sendResendInvite, isLoading } = useResendInviteDriver({
    onSuccess: () => {
      addSnackbar('Invite successfully sent', { variant: 'success' });
    },
  });

  const handleResendInvite = () => {
    if (currentDriverGUID) {
      void nextPlanEstimate.refetch().then(({ data: nextPlan }) => {
        if (nextPlan?.plan) {
          setCurrentModal('plan_upgrade');
        } else {
          sendResendInvite(currentDriverGUID);
        }
      });
    }
  };

  return (
    <>
      <PlanUpdateDrawer
        open={currentModal === 'plan_upgrade' && !nextPlanEstimate.isFetching}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onSubmitSuccess={() => {
          if (currentDriverGUID) {
            sendResendInvite(currentDriverGUID);
          }
          setCurrentModal(undefined);
        }}
      />
      <DriverInviteCancelDialog
        open={isCancelInvite}
        driver={driver.data as DriverDTO}
        onClose={() => {
          setIsCancelInvite(false);
        }}
      />
      {isMobile ? (
        <MenuButton
          size="small"
          aria-label="driver options"
          renderMenu={({ onClose, ...menuProps }) => (
            <Menu {...menuProps} onClose={onClose}>
              <MenuItem
                key="resend"
                onClick={() => {
                  onClose();
                  handleResendInvite();
                }}
              >
                <TextBox>Resend Invite</TextBox>
              </MenuItem>
              <MenuItem
                key="cancel"
                onClick={() => {
                  onClose();
                  setIsCancelInvite(true);
                }}
              >
                <TextBox>Cancel Invite</TextBox>
              </MenuItem>
            </Menu>
          )}
        >
          <MoreHoriz />
        </MenuButton>
      ) : (
        <Inline space="small">
          <Button pending={isLoading} onClick={handleResendInvite}>
            Resend Invite
          </Button>

          <Button
            variant="neutral"
            pending={isLoading}
            onClick={() => {
              setIsCancelInvite(true);
            }}
          >
            Cancel Invite
          </Button>
        </Inline>
      )}
    </>
  );
}
